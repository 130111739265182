import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";

export default {
  install(Vue) {
    Vue.prototype.$global = {
      ///////// Input Required Rules /////////
      requiredRule: [(v) => !!v || i18n.t("Field is required")],
      notRequiredRule: [],
      requiredNumberRule: [
        (v) =>
          parseFloat(String(v).replace(/,/g, "")) > 0 ||
          i18n.t("Field is required"),
      ],
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.t("Field is required"),
      ],
      requiredNumberAcceptZeroRule: [
        (v) => v.toString().length >= 1 || i18n.t("Field is required"),
      ],
      notNegativeNumber: [
        (v) => +v.replace(/[^0-9.-]+/g, "") >= 0 || i18n.t("Field is required"),
      ],
      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
          i18n.t("Field is required"),
      ],
      passwordRule: [
        (v) => !!v || i18n.t("Field is required"),
        (v) =>
          (v && v.length >= 1) ||
          i18n.t("Password must be greater than 5 characters"),
      ],
      percentageRule: [
        (v) =>
          (v.toString().length >= 1 && v <= 100) ||
          i18n.t("percentage not valid"),
      ],
      // username
      usernameRule: [(v) => !!(v || "").match(/^[a-z][0-9a-z_]{2,23}[0-9a-z]$/) || i18n.t("not valid")],
      /////////  Array /////////
      FilterPermissions(Screen_Code) {
        if(store.state.permissions.length > 0){
          var find = store.state.permissions.filter(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find[0];
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.permissions.filter(
          (obj) =>
            obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find.length > 0 ? true : false;
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(ARRAY, SearchIn, filterBy , ReturnElement = null) {
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
        return filter[0][ReturnElement];
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArray(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },
      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      RyalFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "SAR",
        });

        return formatter.format(NUMBER);
      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      ///////// Decryption / Encryption /////////
      CRYPT(KEY, TEXT) {
        // 1
        let textEncrypt =   Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );
        
        return textEncrypt

        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(KEY);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var encrypted = Vue.prototype.$CryptoJS.AES.encrypt(TEXT, x, {iv: iv});
        // return encrypted.toString();

      },
      DECRYPT(KEY, ENCODED) {
        // 1
        let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(ENCODED).toString(
          Vue.prototype.$CryptoJS.enc.Utf8
        );
        return textDecrypt
        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(key);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var decrypted = Vue.prototype.$CryptoJS.AES.decrypt(encryptedString, x,{iv:iv});
        // return decrypted.toString();

      },
    // Dates
    GetCurrentTime() {
      var time = new Date();      
      return   time.getHours() + ":" + time.getMinutes();
    },

    GetCurrentDate() {
      var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
        return [year, month, day].join('-');
      },

    GetFirstDayOfMonth() {
      var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
        return firstDay.toISOString().split('T')[0];
    },
    SubtractDates(FIRST_DATE, SECOND_DATE) {
      // const dateOne = new Date(FIRST_DATE); 
      // const dateTwo = new Date(SECOND_DATE);
      // return dateTwo.getDate() - dateOne.getDate();
      var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
      var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
      var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
      return  Math.ceil(timeDiff / (1000 * 3600 * 24));
      
    },
    SubtractFromDate(DATE, VALUE) {
      const date = new Date(DATE); 
      date.setDate(date.getDate() - Number(VALUE));
      return date.toISOString().split('T')[0];
    },
    AddToDate(DATE, VALUE) {
      const date = new Date(DATE); 
      date.setDate(date.getDate() + Number(VALUE));
      return date.toISOString().split('T')[0];
    },
      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, TOAST = true, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: TOAST,
          timer: TIMER,
          position:
            TOAST == true
              ? store.state.Settings.translation.rtl == true
                ? "top-start"
                : "top-end"
              : "center",
          showConfirmButton: false,
        });
      },
    };
  },
};
