<template>
  <section id="expertsCreatives" class="primary" :class="sectionID">

    <v-row justify="end" align="center" >
      <v-col cols="12" md="6" lg="4" class="pa-6 ps-sm-9 ps-lg-0 pe-lg-10" order="2" order-md="1">
        <h2 class="text-h5 text-lg-h3 text-capitalize my-4 font-weight-regular" data-aos="fade-up" data-aos-duration="2000">
          {{ $store.state.sections?.about_us?.address }}
        </h2>

        <div class="body-1" v-html="$store.state.sections?.about_us?.description" />

      </v-col>
      <v-col cols="12" md="6" class="sectionBg"
        :style="{ backgroundImage: `url(${$store.state.endpointURL + $store.state.sections?.about_us?.image})`}" order="1"
        order-md="2" />
    </v-row>
  </section>
</template>

<script>



export default {
  name: "ExpertsCreatives",
  data: () => ({
    show: false,
    image: require('@/assets/img/png/we-improve-bg.jpg')
  }),
  computed:{
    sectionID() {
      return this.$store.state.sections?.header?.menu[1]?.name_en.replaceAll(' ' ,'') + '_scroll'
    }
  },
  props: {},

};
</script>

<style lang="scss" scoped>
#expertsCreatives {
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  padding-top: 5rem;
  // top: -5rem;

  .subtitle-1,
  .body-1 {
    opacity: 0.5;
  }

  hr {
    opacity: 0.2;
  }

  .text-h6 {
    font-size: 20px !important;
  }

  .sectionBg {
    min-height: calc(100vh - 200px);
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;

    &::before {
      content: '';
      width: 10px;
      height: 60%;
      display: block;
      margin: auto;
      position: absolute;
      background-color: #0675bc;
      left: 0;
      top: 20%;
    }
  }
}</style>
