<template>
  <section id="requestSection" class="light">
    <v-container>
      <v-row>
        <!-- booking type -->
        <v-col cols="12" class="text-center mt-3" v-if="step == 1">
          <h3 class="text-h5 font-weight-bold">{{ $t('choose the place') }}</h3>
          <h6 class="subtitle-2 gray7--text mt-4">
            {{ $t('you can request the required consulting service and choose the time that suits you') }}
          </h6>
          <v-card :min-height="$store.state.windowSize.y - 220" class="rounded-lg shadow  mt-5 pa-5">
            <v-sheet @click="() => { form.type = 1; step = 2 }" height="50"
              class="shadow d-flex rounded-0 justify-space-between align-center my-5">
              <v-col cols="auto" class="subtitle-1 d-flex align-center font-weight-bold">
                <img src="@/assets/img/svg/bookInOffice.svg" class="me-2" alt="">
                {{ $t('attend at office') }}
              </v-col>
              <v-col cols="auto">
                <v-icon>{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
              </v-col>
            </v-sheet>
            <v-sheet @click="() => { form.type = 2; step = 2 }" height="50"
              class="shadow d-flex rounded-0 justify-space-between align-center my-5">
              <v-col cols="auto" class="subtitle-1 d-flex align-center font-weight-bold">
                <img src="@/assets/img/svg/bookInOnline.svg" class="me-2" alt="">
                {{ $t('book online') }}
              </v-col>
              <v-col cols="auto">
                <v-icon>{{ $i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
              </v-col>
            </v-sheet>
          </v-card>
        </v-col>
        <!-- booking date -->
        <v-col cols="12" class="text-center mt-3" v-if="step == 2">
          <h3 class="text-h5 font-weight-bold">{{ $t('select date time') }}</h3>
          <h6 class="subtitle-2 gray7--text mt-4">
            {{ $t('you can request the required consulting service and choose the time that suits you') }}
          </h6>
          <v-card :min-height="$store.state.windowSize.y - 220" :loading="isLoading"
            class="rounded-lg shadow d-flex align-space-between flex-column mt-5 pa-5 pt-0">
            <v-col class="subtitle-1 text-start mt-5" cols="12">
              <div class="mb-5">{{ $t('date') }}</div>
              <v-date-picker @change="step = 3" :min="$global.GetCurrentDate()"
                :locale="$vuetify.rtl == true ? 'ar' : 'en-UK'" header-color="white" class="black--text shadow-none"
                full-width v-model="form.booking_date" />
            </v-col>
            <v-col cols="12" class="mt-auto">
              <button v-ripple class="light subtitle-2" @click="step = 1">
                <v-icon>{{ $i18n.locale == 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
                {{ $t('previous') }}
              </button>
            </v-col>

          </v-card>
        </v-col>
        <!-- booking time -->
        <v-col cols="12" class="text-center mt-3" v-if="step == 3">
          <h3 class="text-h5 font-weight-bold">{{ $t('select date time') }}</h3>
          <h6 class="subtitle-2 gray7--text mt-4">
            {{ $t('you can request the required consulting service and choose the time that suits you') }}
          </h6>
          <v-card :min-height="$store.state.windowSize.y - 220"
            class="rounded-lg shadow d-flex align-space-between flex-column  mt-5 pa-5">
            <v-col class="subtitle-1 text-start" cols="12">
              <div class="mb-5" v-if="times.length">{{ $t('clock') }}</div>
              <v-row>
                <v-col cols="auto" v-for="(time, index) in computedTimes" class="pa-1" :key="index">
                  <v-btn depressed @click="() => { form.time_id = time.time_id; step = $store.state.isAuth ? 6 : 4 }"
                    color="blue12" class="rounded-md" width="120">
                    {{ time.time | time }}
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="!times.length || isLoading">
                  <v-alert outlined v-if="!times.length && !isLoading" text type="info">
                    {{ isLoading ? $t('please wait, loading available times in') : $t('sorry no time available in') }} {{
                      form.booking_date | dateAr }}
                  </v-alert>
                  <v-progress-linear v-else indeterminate color="blue2" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-auto">
              <button v-ripple class="light subtitle-2" @click="step = 2">
                <v-icon>{{ $i18n.locale == 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
                {{ $t('previous') }}
              </button>
            </v-col>
          </v-card>
        </v-col>
        <!-- booking auth -->
        <v-col cols="12" class="text-center mt-3" v-if="step == 4">
          <h3 class="text-h5 font-weight-bold">{{ $t('write your information') }}</h3>
          <h6 class="subtitle-2 gray7--text mt-4">
            {{ $t('you can request the required consulting service and choose the time that suits you') }}
          </h6>
          <AuthForm :authPage="false" :success="(user) => { userData = user; step = 5 }" :step="step"
            :prev="() => step = 3" />
        </v-col>
        <!-- booking otp -->
        <v-col cols="12" class="text-center mt-3" v-if="step == 5">

          <v-card :min-height="$store.state.windowSize.y - 170"
            class="rounded-lg shadow d-flex align-space-between flex-column  mt-5 pa-5">

            <v-col class="subtitle-1 text-center" cols="12">
              <h3 class="text-h6 font-weight-bold">
                {{ userData.email ?
                  $t('a verification code will be sent to your email') :
                  $t('a verification code will be sent to your mobile') }}
              </h3>
              <h6 class="subtitle-2 gray7--text mt-4">
                {{ $t('please verify and fill out here') }}
              </h6>
              <h6 class="subtitle-2 gray7--text mt-4">
                {{ $t('you will be logged in via') }} ({{ userData.email || userData.mobile }})
                <span class="blue1--text text-decoration-underline cursor_pointer" @click="step = 4">{{ $t('edit')
                }}</span>
              </h6>
            </v-col>

            <v-col class="subtitle-1 mt-auto text-start" cols="12">
              <v-row justify="center">
                <v-col cols="12" md="8" lg="6" dir="ltr">
                  <v-otp-input v-model="otp" color="blue2" background-color="light" length="4" />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="mt-auto">
              <v-row align="center">
                <v-col cols="12" md="6">
                  <button v-ripple class="light subtitle-2" @click="step = 4">
                    <v-icon>{{ $i18n.locale == 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
                    {{ $t('previous') }}
                  </button>
                </v-col>
                <v-col cols="12" md="6" v-if="[...otp].length >= 4">
                  <button v-ripple class="primary white--text text-end subtitle-2" @click="confirmOTP">
                    {{ $t('confirm') }}
                    <v-icon color="white">{{ $i18n.locale == 'en' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                  </button>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
        <!-- payment getaway -->
        <v-col cols="12" class="text-center mt-3" v-if="step == 6">

          <v-card :min-height="$store.state.windowSize.y - 170"
            class="rounded-lg shadow d-flex align-space-between flex-column  mt-5 pa-5">

            <v-col class="subtitle-1 text-center" cols="12">
              <h3 class="text-h6 font-weight-bold">{{ $t('choose the payment method - then make the payment') }}</h3>
              <h6 class="subtitle-2 gray7--text mt-4">
                {{ $t('the link will be transferred to you for payment') }}
              </h6>
            </v-col>

            <v-col class="subtitle-1 mt-auto text-start" cols="12">
              <v-row justify="center">
                <v-col cols="auto">
                  <v-card flat class="paymentMethods" @click="setPayment(1)" width="100" height="100" color="light">
                    <v-icon v-if="isLoading && form.payment_method == 1" class="mdi-spin" color="blue5"
                      size="45">mdi-loading</v-icon>
                    <img v-else src="@/assets/img/svg/mastercard.svg" width="60px" alt="" />
                  </v-card>
                </v-col>
                <v-col cols="auto">
                  <v-card flat class="paymentMethods" @click="setPayment(2)" width="100" height="100" color="light">
                    <v-icon v-if="isLoading && form.payment_method == 2" class="mdi-spin" color="blue5"
                      size="45">mdi-loading</v-icon>
                    <img v-else src="@/assets/img/svg/visa.svg" width="60px" alt="" />
                  </v-card>
                </v-col>
                <v-col cols="auto">
                  <v-card flat class="paymentMethods" @click="setPayment(3)" width="100" height="100" color="light">
                    <v-icon v-if="isLoading && form.payment_method == 3" class="mdi-spin" color="blue5"
                      size="45">mdi-loading</v-icon>
                    <img v-else src="@/assets/img/svg/stc.svg" width="60px" alt="" />
                  </v-card>
                </v-col>
                <v-col cols="auto">
                  <v-card flat class="paymentMethods" @click="setPayment(4)" width="100" height="100" color="light">
                    <v-icon v-if="isLoading && form.payment_method == 4" class="mdi-spin" color="blue5"
                      size="45">mdi-loading</v-icon>
                    <img v-else src="@/assets/img/svg/applePay.svg" width="60px" alt="" />
                  </v-card>
                </v-col>
                <v-col cols="auto">
                  <v-card flat class="paymentMethods" @click="setPayment(5)" width="100" height="100" color="light">
                    <v-icon v-if="isLoading && form.payment_method == 5" class="mdi-spin" color="blue5"
                      size="45">mdi-loading</v-icon>
                    <img v-else src="@/assets/img/svg/gpay.svg" width="60px" alt="" />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="mt-auto">
              <v-row align="center">
                <v-col cols="12">
                  <button v-ripple class="light subtitle-2" @click="step = $store.state.isAuth ? 4 : 3">
                    <v-icon>{{ $i18n.locale == 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
                    {{ $t('previous') }}
                  </button>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
  </section>
</template>

<script>
import AuthForm from '@/components/ui/AuthForm';


export default {
  name: "ConsultationRequest",
  data: () => ({
    isLoading: false,
    step: 1,
    times: [],
    form: {
      type: 0,
      booking_date: null,
      time_id: null,
      consultation_id: null,
    },
    userData: {},
    otp: '',
  }),
  components: {
    AuthForm
  },
  mounted() {
    this.form.booking_date = this.$global.GetCurrentDate();
    this.form.consultation_id = +this.$route.params.id;
  },
  computed: {
    currentTime() {
      const now = new Date();
      const formattedTime = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
      return formattedTime;
    },
    computedTimes() {
      if (this.form.booking_date == this.$global.GetCurrentDate()) {
        return this.times.filter(time => time.time >= this.currentTime)
      } else {
        return this.times
      }
    }
  },
  watch: {
    'form.booking_date'() {
      if (this.form.booking_date) {
        this.getData();
      }
    },
    step() {

    },
    otp() {
      if ([...this.otp].length >= 4) {
        this.confirmOTP();
      }
    },
  },
  props: {

  },
  methods: {
    getData() {
      this.isLoading = true;
      this.times = []
      this.$api.GET_METHOD(`booking_get_create?date=${this.form.booking_date || ""}`)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.times = response.data.avalible_times[this.form.booking_date] || [];
            if (this.step == 2) {
              this.step = 3
            }
          }
        });
    },
    confirmOTP() {
      this.isLoading = true;
      let requestBody = {};
      requestBody.mobile = this.userData.mobile;
      this.userData.mobile ? requestBody.mobile = this.userData.mobile : requestBody.email = this.userData.email;
      requestBody.otp = this.otp
      this.$api.POST_METHOD('login_otp', requestBody).then((response) => {
        this.isLoading = false;
        if (response.check && this.step == 5) {
          this.$api.SET_AUTH(response.data)
          this.step = 6
        } else {
          this.otp = '';
        }
      });
    },
    setPayment(payment_method) {
      this.form.payment_method = payment_method
      this.isLoading = true;
      this.$api.POST_METHOD(`booking`, this.form)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            let time = this.times.find(time => time.time_id == this.form.time_id)?.time
            this.$router.push(`/payment-success?date=${this.form.booking_date}&time=${time}`)
          }
        });
    },
  },

};
</script>

<style lang="scss" scoped>
#requestSection {
  position: relative !important;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 70px;

  .v-card {
    .v-sheet {
      transition: 0.3s;
      cursor: pointer;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;

      &:hover {
        border-bottom: 5px solid var(--blue9);
      }
    }
  }

  button:not(.v-btn) {
    width: 100%;
    margin-top: 15px;
    height: 40px;
    text-align: start;
    padding: 5px 10px;
    border-radius: 8px;
    transition: 0.3s;

    i {
      color: #000;
    }

    &:hover {
      background-color: var(--blue2) !important;
      color: #fff;

      i {
        color: #fff;
      }
    }
  }

  .paymentMethods {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 0;
  }
}
</style>
