import { render, staticRenderFns } from "./AuthForm.vue?vue&type=template&id=19f925f6&scoped=true&"
import script from "./AuthForm.vue?vue&type=script&lang=js&"
export * from "./AuthForm.vue?vue&type=script&lang=js&"
import style0 from "./AuthForm.vue?vue&type=style&index=0&id=19f925f6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f925f6",
  null
  
)

export default component.exports