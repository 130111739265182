<template>
  <v-fade-transition>
    <v-btn @click="scrollTop" class="shadow-md" style="z-index: 100;" v-if="$store.state.windowSize.offsetTop >= 400" fab
      bottom fixed depressed color="accent" small :left="$i18n.locale === 'ar'" :right="$i18n.locale !== 'ar'">
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-fade-transition>
</template>


<script>
export default {
  name: "ScrollTop",
  data: () => ({}),
  props: {},
  computed: {},
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }
};
</script>
