<template>
  <v-container fluid class="pa-0">
    <LandingSection />
    <ExpertsCreatives />
    <ServicesSection />
    <IntroSection />
    <!-- <ContactUSForm /> -->
    <!-- <ClientsSection /> -->
    <!-- <OurMission /> -->
    <!-- <TeamSection /> -->
  </v-container>
</template>


<script>
import LandingSection from '@/components/ui/LandingSection.vue';
// import ClientsSection from '@/components/ui/ClientsSection.vue';
import IntroSection from '@/components/ui/IntroSection.vue';
import ServicesSection from '@/components/ui/ServicesSection.vue';
// import OurMission from '@/components/ui/OurMission.vue';
import ExpertsCreatives from '@/components/ui/ExpertsCreatives.vue';
// import ContactUSForm from '@/components/ui/ContactUSForm.vue';
// import TeamSection from '@/components/ui/TeamSection.vue';
export default {
  name: "HomeModule",
  components: {
    LandingSection,
    // ClientsSection,
    IntroSection,
    ServicesSection,
    // OurMission,
    ExpertsCreatives,
    // ContactUSForm,
    // TeamSection
},
  data: () => ({

  }),
  watch: {

  },
  created() {

  },
  destroyed() {

  },
  computed: {},
  mounted() {
  },
  methods: {

  }

};
</script>


<style lang="scss" scoped></style>