<template>
  <section id="servicesSection" :class="sectionID">
    <v-container class="servicesContainer">
      <v-row justify="center">
        <v-col cols="auto" class="text-center">

          <h2 class="text-h4 text-lg-h3 text-capitalize font-weight-regular">{{
            $store.state.sections?.services_header?.[`department_address_${$i18n.locale}`] }}</h2>

        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col cols="12" sm="6" lg="3" v-for="(service, i) in $store.state.sections?.services" v-show="service.active"
          :data-aos-duration="1000 + (300 * i)" data-aos-delay="0" :key="service.id">
          <v-expand-x-transition>
          <v-card class="service_card shadow rounded-0 pa-3 pa-md-6 pa-lg-10" max-height="300px" v-intersect="onIntersect">
            <img :src="$store.state.endpointURL + service.image" alt="value solutions">
            <h5 class="text-h5 text-capitalize my-5">{{ service.address }}</h5>
            <p class="desc body-1">
              {{ service.description }}
            </p>
            <div class="read_more cursor_pointer mt-2" @click="readMore">{{ $t('read more') }}</div>
          </v-card>
        </v-expand-x-transition>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>



export default {
  name: "ServicesSection",
  data: () => ({
    isIntersecting: false,
  }),
  computed: {
    sectionID() {
      return this.$store.state.sections?.header?.menu[2]?.name_en.replaceAll(' ' ,'') + '_scroll'
    }
  },
  methods: {
    readMore(event) {
      let parent = event.target.parentElement
      let description = event.target.previousSibling
      console.log('parent',parent.style.maxHeight = 'unset');

      if (description.style.display == 'unset') {
        parent.style.maxHeight = '300px'
        event.target.innerHTML = this.$i18n.t('read more')
        description.style.display = '-webkit-box';
      } else {
        parent.style.maxHeight = '100%'
        event.target.innerHTML = this.$i18n.t('read less')
        description.style.display = 'unset'; 
      }
    },
    observeCardLines() {
      const cards = document.querySelectorAll('.v-card');
      cards.forEach(card => {
        const textElement = card.querySelector('.desc');
        const read_more = card.querySelector('.read_more');

        if (textElement && read_more) {
          // Calculate the height of a single line of text
          const lineHeight = parseFloat(window.getComputedStyle(textElement).lineHeight);
          console.log('lineHeight', lineHeight, 'scrollHeight', textElement.scrollHeight);
          // Check if the text content exceeds two line
          if (textElement.scrollHeight > 51) {
            read_more.style.visibility = 'unset'; 
          }
        }
      });

    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
      if (this.isIntersecting) {
        this.observeCardLines();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
#servicesSection {
  position: relative;
  top: -5rem;
  padding-top: 10rem !important;

  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 400px;
    background: #edf3f6;
    top: 5rem;
    right: 0;
    z-index: 1;

    @media screen and (max-width: 991px) {
      height: 550px;
    }
  }

  .desc{
    transition: 500ms;
  }
  .servicesContainer {
    position: relative;
    z-index: 5;

    .text-h5 {
      font-size: 20px !important;
      font-weight: 700;
      line-height: 34px;
    }

    .body-1 {
      font-size: 16px !important;
      font-weight: 500;
      opacity: 0.6;
    }
  }

  .read_more {
    visibility: hidden;
  }

  .v-card {
    background-color: #FFFFFF;
    transition: 0.3s;
    // transition: max-height 0.5s ease-out;

    p {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    img {
      height: 60px;
    }

    &:hover {
      background-color: #0675bc;
      color: #FFFFFF !important;

      img {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }
    }
  }
}
</style>
