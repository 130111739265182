<template>
  <v-text-field v-model="input" :solo="solo" outlined :reverse="reverse" :dense="dense" :name="name"
    :autofocus="autofocus" @keydown.enter="keydownEnter()" :class="classes" :disabled="disabled" :readonly="readonly"
    :rules="required === true ? validation_type || $global.requiredRule : $global.notRequiredRule" @keydown="keydown"
    :loading="isLoading" rounded-md :color="color" :background-color="background" :append-icon="icon" @focusout="focusout"
    @focusin="focusinInput" :hide-details="hideDetails == true ? 'auto' : false" :type="type"
    :placeholder="$i18n.t(placeholder)" :error-messages="$i18n.t(errorMessage)" :persistent-hint="hint ? true : false"
    :hint="$i18n.t(hint)">
    <template v-slot:label>
      <label v-if="inputDesign == 'default'">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true && label">*</span>
      </label>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
  </v-text-field>
</template>


<script>
export default {
  name: "TextInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    validation_type: { default: null },
    value: { default: null },
    required: { default: false },
    readonly: { default: false },
    autofocus: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    focusout: { default: Function },
    focusin: { default: Function },
    tabKey: { default: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    background: { default: null },
    icon: { default: "" },
    hideDetails: { default: false },
    inputDesign: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    errorMessage: { default: null },
    hint: { default: null },
    reverse: { default: false }
  },
  data: () => ({

  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    keydown(e) {
      console.log(e);
      if (e.code == "Tab") {
        this.tabKey(true)
      }
    },
    focusinInput() {
      if (!this.readonly) {
        this.focusin()
      }
    }
  },
};
</script>



<style scoped lang="scss"></style>
