<template>
  <span>
    <label v-if="inputDesign == 'default'">
      {{ $t(label) }}
      <span class="error--text" v-if="required == true">*</span>
    </label>
    <!-- <ckeditor v-model="input" /> -->
    <vue-editor  @blur="onClickOuter(name, input, $event, type, required, $event)"
      v-model="input"></vue-editor>
  </span>
</template>


<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "TextEditor",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    required: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    background: { default: null},
    icon: { default: "" },
    hideDetails: { default: false },
    inputDesign: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    errorMessage: { default: null },
    hint: { default: null },
    reverse: { default: false }
  },
  data: () => ({

  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
  components:{
    VueEditor,
  }
};
</script>



<style scoped lang="scss">

</style>
