<template>
  <v-form ref="form" v-model="authValid" lazy-validation @submit.prevent="() => { return false }">
    <v-card :min-height="$store.state.windowSize.y - 220"
      class="rounded-lg shadow d-flex align-space-between flex-column mt-5 pa-5">
      <v-col class="subtitle-1 text-start" cols="12">
        <v-row :justify="authTab ? 'center' : 'start'">
          <v-col cols="12" class="pt-0 mt-n3">
            <v-tabs centered v-model="authTab" color="blue9">
              <v-tab class="blue9--text" :class="{ 'blue12': !authTab }">{{ $t('create an account') }}</v-tab>
              <v-tab class="blue9--text" :class="{ 'blue12': authTab }">{{ $t('login') }}</v-tab>
            </v-tabs>

            <v-divider />
          </v-col>
          <template v-if="!authTab">
            <GenericInput type="text" :value="registerForm.user_full_name" @input="registerForm.user_full_name = $event"
              label="name" :required="true" :isLoading="false" :cols="[12, 6, 6]" hint="Field is required"
              placeholder="ex : mohamed ahmed" :keydownEnter="authentication" />
            <GenericInput type="email" :value="registerForm.email" @input="registerForm.email = $event" label="email"
              :required="true" :isLoading="false" :cols="[12, 6, 6]" hint="Field is required"
              placeholder="ex : support@valuesolutionfc.com" :keydownEnter="authentication" />
            <GenericInput type="email" :keydownEnter="authentication" :value="registerForm.confirm_email"
              @input="registerForm.confirm_email = $event" :validation_type="confirmValidation" label="confirm email"
              :required="true" :isLoading="false" :cols="[12, 6, 6]" hint="Field is required"
              placeholder="ex : support@valuesolutionfc.com" />
            <GenericInput type="mobile" :keydownEnter="authentication" :value="registerForm.mobile"
              @input="registerForm.mobile = $event" label="mobile" :required="true" :isLoading="false" :cols="[12, 6, 6]"
              hint="Field is required" placeholder="ex : 056789910" />
            <GenericInput type="text" :value="registerForm.company_name" @input="registerForm.company_name = $event"
              :keydownEnter="authentication" label="company name" :required="true" :isLoading="false" :cols="[12, 12, 12]"
              placeholder="ex : aramco" />
          </template>
          <template v-else>
            <v-col cols="12" sm="9" md="7" lg="6" xl="5">
              <GenericInput type="radiogroup"
                :lookups="[{ id: 1, name: $i18n.t('mobile') }, { id: 2, name: $i18n.t('email') }]" :value="loginForm.type"
                @input="loginForm.type = $event" label="login with" :required="true" selected_label="name"
                selected_prop="id" :cols="[12, 12, 12]" inputDesign="inline_label" :keydownEnter="authentication" />

              <GenericInput v-if="loginForm.type == 1" type="mobile" :value="loginForm.mobile"
                :keydownEnter="authentication" @input="loginForm.mobile = $event" label="mobile" :required="true"
                :isLoading="false" :cols="[12, 12, 12]" hint="Field is required" placeholder="ex : 056789910" />

              <GenericInput v-else type="email" :value="loginForm.email" @input="loginForm.email = $event" label="email"
                :required="true" :isLoading="false" :cols="[12, 12, 12]" hint="Field is required"
                placeholder="ex : support@valuesolutionfc.com" />
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12" class="mt-auto">
        <v-row align="center">
          <v-col cols="12" md="6">
            <button v-if="!authPage" v-ripple class="light subtitle-2" @click="prev" type="button">
              <v-icon>{{ $i18n.locale == 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
              {{ $t('previous') }}
            </button>
          </v-col>
          <v-col cols="12" md="6">
            <button v-ripple class="primary white--text text-end subtitle-2" type="button" :disabled="!(authValid)"
              @click="authentication">
              {{ $t('confirm') }}
              <v-icon color="white" class="mdi-spin" v-if="authRequestLoading">mdi-loading</v-icon>
              <v-icon color="white" v-else>{{ $i18n.locale == 'en' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
            </button>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-form>
</template>


<script>
import GenericInput from './GenericInput.vue';

export default {
  name: "AuthForm",
  props: {
    authPage: { default: false },
    step: { default: 0 },
    success: { default: Function },
    prev: { default: Function },
  },
  data: () => ({
    authValid: false,
    authTab: 0,
    registerForm: {
      user_full_name: null,
      email: null,
      confirm_email: null,
      mobile: null,
      company_name: null,
    },
    loginForm: {
      type: 1,
      email: null,
      mobile: null,
    },
    authRequestLoading: false,
  }),
  computed: {
    confirmValidation() {
      return [(v) => !!(v || "").match(/@/) || this.$i18n.t("Field is required"),
      (v) =>
        (v && v == this.registerForm.email) ||
        this.$i18n.t("email not matches")]
    }
  },
  watch: {
    'registerForm.email'() {
      this.registerForm.confirm_email = null;
    }
  },
  mounted() {

  },
  beforeDestroy: function () {
  },
  methods: {
    authentication() {
      if (this.$refs.form.validate() && !this.authRequestLoading) {
        let endPoint = this.authTab ? 'user_login' : 'register';
        this.authRequestLoading = true;
        this.$api.POST_METHOD(endPoint, this.authTab ? this.loginForm : this.registerForm)
          .then((response) => {
            this.authRequestLoading = false;
            if (response.check) {
              this.success(this.authTab ? this.loginForm : this.registerForm);
            }
          });
      }
    },
  },
  components: { GenericInput }
};
</script>



<style scoped lang="scss">
button:not(.v-btn) {
  width: 100%;
  margin-top: 15px;
  height: 40px;
  text-align: start;
  padding: 5px 10px;
  border-radius: 8px;
  transition: 0.3s;

  i {
    color: #000;
  }

  &:hover {
    background-color: var(--blue2) !important;
    color: #fff;

    i {
      color: #fff;
    }
  }
}
</style>
