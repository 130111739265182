<template>
  <v-container style="" class="d-flex align-center justify-center bgError">
    <div>
      <v-row align="center">
        <v-col cols="auto">
          <img src="@/assets/img/png/error404.png" alt="value solutions" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
  height: calc(100vh - 68px);
  margin-top:68px ;
  img {
    margin: auto;
    display: block;
    max-height: calc(100vh - 68px);
  }
}
</style>

<script>
export default {
  name: "Error404",
  computed: {},
  mounted() {
  },
};
</script>
