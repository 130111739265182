<template>
  <v-text-field v-model.number="input" :solo="solo" outlined :reverse="reverse" :dense="dense" :name="name"
    @keydown.enter="keydownEnter()" :class="classes" :disabled="disabled" :rules="required == true
      ? acceptZero ? $global.requiredNumberAcceptZeroRule : $global.requiredRule
      : $global.notRequiredRule" :loading="isLoading" rounded-md :color="color" :background-color="background"
    :append-icon="icon" :hide-details="hideDetails == true ? 'auto' : false" :type="type"
    :error-messages="$i18n.t(errorMessage)" :placeholder="$i18n.t(placeholder)" :persistent-hint="hint ? true : false" :hint="$i18n.t(hint)">
    <template v-slot:label>
      <label v-if="inputDesign == 'default'">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true">*</span>
      </label>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
  </v-text-field>
</template>


<script>
export default {
  name: "NumberInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: 'number' },
    value: { default: null },
    required: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    background: { default: null },
    icon: { default: "" },
    hideDetails: { default: false },
    inputDesign: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false },
    errorMessage: { default: null },
    hint: { default: null },
    acceptZero: { default: false },
  },
  data: () => ({

  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {

  },
};
</script>



<style scoped lang="scss"></style>
