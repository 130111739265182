<template>
  <section id="landingSection" :class="sectionID">
    <v-carousel cycle height="100vh" :show-arrows="false" :hide-delimiters="true" v-model="activeCarousel">
      <v-carousel-item v-for="(carousel, i) in $store.state.sections.slides" :key="i"
        :style="{ backgroundImage: `url(${$store.state.endpointURL + carousel.image})` }" class="carousel-item">
        <div class="layer" />

        <img src="@/assets/img/svg/HeroLogo.svg" :data-aos="!$vuetify.rtl ? 'fade-right' : 'fade-left'"
          data-aos-duration="2000" data-aos-delay="0" data-aos-offset="-1500" data-aos-easing="ease-in-sine"
          :key="carouselKey + randomKey" class="layer_c" :alt="carousel.header">

        <v-container class="carousel_item">
          <v-row justify="center" justify-lg="start">
            <v-col cols="12" md="6" lg="8">
              <v-col v-if="carousel.header_active" cols="12" class="text-center text-lg-start">
                <h1 class="text-h2" data-aos="fade-up" data-aos-duration="2000">
                  {{ carousel.header }}
                </h1>
              </v-col>
              <v-col v-if="carousel.text_active" cols="12" class="my-3 text-center text-lg-start">
                <h5 class="text-h6" data-aos="fade-down" data-aos-duration="2000" v-html="carousel.text" />
              </v-col>
              <v-col v-if="carousel.btn_active" cols="12" md="auto" class="my-3 d-flex d-lg-block justify-center">
                <v-btn color="primary" id="landing_btn" depressed :to="`/consultations`" x-large min-width="230"
                  data-aos="fade-up" data-aos-duration="2000" class="rounded-0 pa-7 font-weight-bold text-capitalize">
                  <!-- {{ $t('free consultation') }} -->
                  {{ carousel.btn }}
                </v-btn>
              </v-col>
            </v-col>


          </v-row>

        </v-container>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>

export default {
  name: "LandingSection",
  props: {},
  data: () => ({
    activeCarousel: 0,
    carouselKey: 0,
  }),
  computed: {
    randomKey() {
      return Math.random()
    },
    hasLink() {
      return this.$route.hash.slice(1)
    },
    sectionID() {
      return this.$store.state.sections?.header?.menu[0]?.name_en.replaceAll(' ' ,'') + '_scroll'
    }
  },
  watch: {
    activeCarousel() {
      this.carouselKey++
    }
  },
  mounted() {
  },
  created() {
  },
  destroyed() {

  },
  methods: {

  },
  components: {  }
};
</script>


<style lang="scss" scoped>
#landingSection {
  position: relative !important;
  position: relative;
  height: 100vh;
  top: 0;
  padding-top: 68px; // appBar height
  .layer {
    background: #002442 !important;
    content: '';
    position: absolute !important;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5 !important;
  }

  .carousel-item {
    background-size: cover !important;
    background-position: center !important;
    display: flex;
    align-items: center;
  }

  .layer_c {
    position: absolute;
    right: 10%;
    top: 0%;
    opacity: 1;
    height: calc(100vh - 90px);

  }


  .carousel_item {
    color: #FFFFFF;
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 10 !important;

    .text-h2 {
      text-transform: capitalize;
      font-weight: 600;
      z-index: 5 !important;
      position: relative;

      @media screen and (max-width: 991px) {
        font-size: 40px !important;
        text-align: center;
      }
    }

    .text-h6 {
      @media screen and (max-width: 991px) {
        text-align: center;
      }
    }

    .v-btn {
      margin: auto;
    }
  }

}

#landing_btn {
  border-radius: 20px !important;
}

.v-application--is-rtl {
  #landingSection {
    .layer_c {
      left: 10% !important;
      right: unset !important;
    }
  }
}
</style>