<template>
  <section id="requestSection" class="light">
    <v-container>
      <v-row>
        <!-- success -->
        <v-col cols="12" class="text-center mt-3">

          <v-card :min-height="$store.state.windowSize.y - 170"
            class="rounded-lg shadow d-flex align-center flex-column  mt-5 pa-5">

            <v-col class="subtitle-1 text-center" cols="12">
              <img src="@/assets/img/svg/successing.svg" alt="">
              <h3 class="text-h6 font-weight-bold success--text">
                {{ $t('your reservation was successful') }}</h3>
              <h3 class="text-h6 font-weight-bold success--text mt-3">
                {{ $t('we are waiting for you, do not be late for us.') }}</h3>
              <h6 class="subtitle-2 gray7--text mt-4">
                {{ $t('you can modify your reservation through a link sent to your email') }}
              </h6>
              <h3 class="text-h6 font-weight-bold  mt-4">
                {{ $t('your appointment is on') }} {{ date | dateAr }}
                -
                {{ $t('clock') }} {{ time | time }}
              </h3>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "ConsultationRequestSuccess",
  data: () => ({
    time: null,
    date: null,
  }),
  components: {
  },
  mounted() {
      this.time = this.$router.currentRoute.query.time
      this.date = this.$router.currentRoute.query.date
  },
  computed: {

  },
  watch: {

  },
  props: {

  },
  methods: {

  },

};
</script>

<style lang="scss" scoped>
#requestSection {
  position: relative !important;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 70px;

  .v-card {
    .v-sheet {
      transition: 0.3s;
      cursor: pointer;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;

      &:hover {
        border-bottom: 5px solid var(--blue9);
      }
    }
  }

  button:not(.v-btn) {
    width: 100%;
    margin-top: 15px;
    height: 40px;
    text-align: start;
    padding: 5px 10px;
    border-radius: 8px;
    transition: 0.3s;

    i {
      color: #000;
    }

    &:hover {
      background-color: var(--blue2) !important;
      color: #fff;

      i {
        color: #fff;
      }
    }
  }

  .paymentMethods {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 0;
  }
}
</style>
