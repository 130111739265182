import { render, staticRenderFns } from "./ConsultationRequestSuccess.vue?vue&type=template&id=68342b10&scoped=true&"
import script from "./ConsultationRequestSuccess.vue?vue&type=script&lang=js&"
export * from "./ConsultationRequestSuccess.vue?vue&type=script&lang=js&"
import style0 from "./ConsultationRequestSuccess.vue?vue&type=style&index=0&id=68342b10&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68342b10",
  null
  
)

export default component.exports