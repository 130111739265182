<template>
  <section id="requestSection" class="light">
    <v-container>
      <v-row>

        <!-- booking auth -->
        <v-col cols="12" class="text-center mt-3" v-if="step == 1">
          <AuthForm :authPage="true" :success="(user) => { userData = user; step = 2 }" />
        </v-col>
        <!-- booking otp -->
        <v-col cols="12" class="text-center mt-3" v-if="step == 2">

          <v-card :min-height="$store.state.windowSize.y - 170"
            class="rounded-lg shadow d-flex align-space-between flex-column  mt-5 pa-5">

            <v-col class="subtitle-1 text-center" cols="12">
              <h3 class="text-h6 font-weight-bold">
                {{ userData.email ?
                  $t('a verification code will be sent to your email') :
                  $t('a verification code will be sent to your mobile') }}</h3>
              <h6 class="subtitle-2 gray7--text mt-4">
                {{ $t('please verify and fill out here') }}
              </h6>
              <h6 class="subtitle-2 gray7--text mt-4">
                {{ $t('you will be logged in via') }} ({{ userData.email || userData.mobile }})
                <span class="blue1--text text-decoration-underline cursor_pointer" @click="step = 1">{{ $t('edit')
                }}</span>
              </h6>
            </v-col>

            <v-col class="subtitle-1 mt-auto text-start" cols="12">
              <v-row justify="center">
                <v-col cols="12" md="8" lg="6" dir="ltr">
                  <v-otp-input v-model="otp" focus color="blue2" background-color="light" length="4" />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="mt-auto">
              <v-row align="center">
                <v-col cols="12" md="6">
                  <button v-ripple class="light subtitle-2" @click="step = 1">
                    <v-icon>{{ $i18n.locale == 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right' }}</v-icon>
                    {{ $t('previous') }}
                  </button>
                </v-col>
                <v-col cols="12" md="6" v-if="[...otp].length >= 4">
                  <button v-ripple class="primary white--text text-end subtitle-2" @click="confirmOTP">
                    {{ $t('confirm') }}
                    <v-icon color="white">{{ $i18n.locale == 'en' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                  </button>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import AuthForm from '@/components/ui/AuthForm';


export default {
  name: "AuthenticationPage",
  data: () => ({
    isLoading: false,
    step: 1,
    userData: {},
    otp: '',
  }),
  components: {
    AuthForm
  },
  mounted() {
    this.$api.LOGOUT()
  },
  computed: {},
  watch: {
    otp() {
      if ([...this.otp].length >= 4) {
        this.confirmOTP();
      }
    },
  },
  props: {

  },
  methods: {
    confirmOTP() {
      this.isLoading = true;
      let requestBody = {};
      requestBody.mobile = this.userData.mobile;
      this.userData.mobile ? requestBody.mobile = this.userData.mobile : requestBody.email = this.userData.email;
      requestBody.otp = this.otp
      this.$api.POST_METHOD('login_otp', requestBody).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.$api.SET_AUTH(response.data)
          this.$router.push('/profile');
        } else {
          this.otp = '';
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
#requestSection {
  position: relative !important;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 70px;

  .v-card {
    .v-sheet {
      transition: 0.3s;
      cursor: pointer;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;

      &:hover {
        border-bottom: 5px solid var(--blue9);
      }
    }
  }

  button:not(.v-btn) {
    width: 100%;
    margin-top: 15px;
    height: 40px;
    text-align: start;
    padding: 5px 10px;
    border-radius: 8px;
    transition: 0.3s;

    i {
      color: #000;
    }

    &:hover {
      background-color: var(--blue2) !important;
      color: #fff;

      i {
        color: #fff;
      }
    }
  }

  .paymentMethods {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 0;
  }
}
</style>
