<template>
  <section>

    <template v-for="(consultation, index) in consultations">
      <v-row class="mt-16 px-5 pt-16" v-if="index == 0" :class="checkScreenWidth ? '' : 'px-3'" :key="index">
        <v-col cols="12" lg="6" :class="checkScreenWidth ? '' : 'd-flex justify-center'">
          <div class="book_consultation px-5" :class="checkScreenWidth ? '' : 'd-flex justify-center px-0'">
            <div class="d-flex flex-column">

              <span>
                <h2 class="mb-2">{{ consultation[`title_${$i18n.locale}`] }}</h2>
                <span class="consultion_type" v-html="consultation[`description_${$i18n.locale}`]" />
              </span>

              <span class="Scroll_down d-flex align-center">
                <v-img max-width="40" src="@/assets/img/svg/book_consultation_mouse.svg"
                  :alt="consultation[`title_${$i18n.locale}`]" />
                <span class="mx-5">{{ $t('Scroll down') }}</span>
              </span>

            </div>
          </div>
        </v-col>
        <v-col v-if="checkScreenWidth" cols="12" lg="6" class="d-flex justify-end pe-15">
          <v-img max-height="551" max-width="472" :src="$store.state.endpointURL.slice(0, -1) + consultation.image"
            :alt="consultation[`title_${$i18n.locale}`]" />
        </v-col>
      </v-row>

      <v-row class="my-16 financial_consulting_parent" v-else :class="checkScreenWidth ? 'pa-16' : 'pa-5'" :key="index">
        <v-col class="financial_consulting" :data-aos="(index % 2 === 0) ? 'fade-left' : 'fade-right'"
          data-aos-easing="linear">
          <v-row class=" d-flex align-center">
            <v-col cols="12" lg="8" :class="checkScreenWidth ? 'pa-16' : 'px-7'"
              :order="(index % 2 === 0) && checkScreenWidth ? 1 : 2">
              <div>
                <h4 class="mb-7">{{ consultation[`title_${$i18n.locale}`] }}</h4>
                <p class="details" v-html="consultation[`description_${$i18n.locale}`]" />
                <v-btn class="white--text blue6 px-5 mt-3" elevation="0"
                  :to="`/consultation-request/${consultation.id}/${consultation[`title_${$i18n.locale}`]}/`">
                  {{ $t('booking now') }}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" lg="4" class="d-flex " :order="(index % 2 === 0) && checkScreenWidth ? 2 : 1"
              :class="checkScreenWidth ? (index % 2 === 0) ? 'justify-end' : 'justify-start' : 'justify-center'">
              <v-img max-width="400" :src="$store.state.endpointURL + consultation.image"
                :alt="consultation[`title_${$i18n.locale}`]" :class="checkScreenWidth ? 'mt-n16' : ''"
                data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1700"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>



  </section>
</template>
  
  
<script>
export default {
  name: "ConsultationPage",
  data: () => ({

  }),
  mounted() {
    this.getData()
  },
  computed: {
    checkScreenWidth() {
      return this.$vuetify.breakpoint.width >= 1264;
    },
    consultations() {
      return this.$store.state.consultations || [];
    },
  },
  methods: {

  },

  components: {

  },
}
</script>

<style lang="scss" scoped>
// Book a consultation
.book_consultation {
  width: 31.375rem;
  height: 35rem;
  padding: 9rem 5rem;
  border-radius: 5.31056rem var(--itemCard, 0rem);
  background: var(--SconderySelcted, rgba(51, 204, 255, 0.10));
}

.consultion_type {
  color: var(--gray-6, #77757F);
  font-size: .8rem;
  font-weight: 700;
}

.Scroll_down {
  margin-top: 11rem;

}

// Financial consulting
@media (min-width: 580px) and (max-width: 1264px) {
  .financial_consulting_parent {
    padding: 10rem !important
  }
}

.financial_consulting {
  border-radius: 2rem 2rem 2rem 4.625rem;
  border-bottom: 2px solid var(--SconderyHover, rgba(51, 204, 255, 0.20));
  border-left: 2px solid var(--SconderyHover, rgba(51, 204, 255, 0.20));
  background: rgba(51, 204, 255, 0.10);
}

.financial_consulting .details {
  color: var(--gray-7, #8E8C94);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.875rem;
  /* 214.286% */
}
</style>