import Vue from "vue";
import i18n from "../plugins/i18n";
Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("encrypt", function (value) {
  return Vue.prototype.$CryptoJS.enc.Base64.stringify(
    Vue.prototype.$CryptoJS.enc.Utf8.parse(value)
  );
});
Vue.filter("decrypt", function (value) {
  return Vue.prototype.$CryptoJS.enc.Base64.parse(value).toString(
    Vue.prototype.$CryptoJS.enc.Utf8
  );
});
Vue.filter("double", function (NUMBER) {
  return  Number(NUMBER).toFixed(2);
});
Vue.filter("float", function (NUMBER) {
  let number = Number(NUMBER).toFixed(2)
  return  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});
Vue.filter("dateAr", function (value) {
  return new Date(value).toLocaleDateString("ar", {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: "numeric",
    // minute: "numeric",
  });
});
Vue.filter("dateEn", function (value) {
  return new Date(value).toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
});
Vue.filter("time", function (value) {
  const date = new Date().toLocaleDateString();
  const time = new Date(`${date} ${value}:00`).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  if (!value) {
    return '--:--:--';
  }

  const [formattedTime, period] = time.split(' ');
  const [hour, minute] = formattedTime.split(':');

  let periodAbbreviation = '';
  if (period === 'AM') {
    periodAbbreviation =  i18n.t('Abbreviation');
  } else if (period === 'PM') {
    periodAbbreviation = i18n.t("Evening");
  } else {
    periodAbbreviation = 'Unknown';
  }
  const timeWithAbbreviation = `${hour}:${minute} ${periodAbbreviation}`;

  return timeWithAbbreviation;
});
export default ({
});