<template>
  <section id="introSection"  :class="sectionID" v-intersect="onIntersect">
    <v-container :fluid="$vuetify.breakpoint.name !== 'lg' && $vuetify.breakpoint.name !== 'xl'"
      class="pa-7 pa-md-10 pa-lg-1">

      <v-row justify="center" align="center">
        <v-col cols="12" sm="10" md="6">
          <h4 class="text-h5 text-lg-h4 font-weight-bold my-5 text-capitalize">{{ $store.state.sections?.cases?.intro_address }}</h4>

          <h6 class="body-1 text-capitalize font-weight-bold accent--text my-6">
            {{ $store.state.sections.cases?.description }}.
          </h6>

          <div class="subtitle-2 gray7--text font-weight-bold mb-2"
            v-for="(intro, i) in $store.state.sections.cases?.content" :key="i" v-show="intro.active">
            <v-icon size="18" class="mt-1" color="accent">mdi-check-circle</v-icon> {{ intro[`name_${$i18n.locale}`] }}.
          </div>

          <div class="d-flex justify-space-between mt-8">
            <div class="subtitle-1 mb-2">{{ $t('consulting') }}</div>
            <div class="subtitle-1 mb-2">{{ 77 }} %</div>
          </div>
          <v-progress-linear color="accent" value="77" />
        </v-col>

        <!-- <v-col cols="12" sm="10" md="4">
          <v-img contain :src="$store.state.endpointURL + $store.state.sections.cases?.image" width="400" height="528"
            class="d-block ma-auto" alt="value solutions" />
        </v-col> -->

        <v-col cols="12" sm="10" md="6" class="pa-5 pa-md-12">

          <v-card class="statisticsCard" v-for="(cases, i) in $store.state.sections.cases?.cases" :key="i"
            v-show="cases.active">
            <v-row justify="space-between" align="center">
              <v-col cols="auto" md="7">
                <h5 class="text-h3 font-weight-regular text-md-end counter" v-html="0" :data-target="cases.number" />
                <h6 class="subtitle-1">{{ cases[`content_${$i18n.locale}`] }}</h6>
              </v-col>
              <v-col cols="auto" md="5">
                <div class="imgContainer">
                  <img :src="$store.state.endpointURL + cases.case_image" height="60px" alt="value solutions">
                  <!-- <img src="@/assets/img/png/help.png" height="60px" alt="value solutions"> -->
                  <!-- <img src="@/assets/img/png/help_white.png" height="60px" alt="value solutions"> -->
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-10" v-if="i + 1 !== $store.state.sections.cases.cases?.length" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>



export default {
  name: "IntroSection",
  props: {},
  data: () => ({
    completedCases: 480,
    happyCustomers: 375,
    expert: 25,
  }),
  computed:{
    sectionID() {
      return this.$store.state.sections?.header?.menu[3]?.name_en.replaceAll(' ' ,'') + '_scroll'
    }
  },
  watch: {
  },
  mounted() {
  },
  created() {
  },
  destroyed() {

  },
  methods: {
    onIntersect() {
      setTimeout(() => {
            const counters = document.querySelectorAll('.counter')
            counters.forEach(counter => {
              counter.innerText = '0'
              const updateCounter = () => {
                const target = +counter.getAttribute('data-target')
                const c = +counter.innerText
                const increment = target / 200
                if (c < target) {
                  counter.innerText = `${Math.ceil(c + increment)}`
                  if (target <= 10) {
                    setTimeout(updateCounter, 200)
                  }
                  else if (target <= 50) {
                    setTimeout(updateCounter, 30)
                  }
                  else if (target <= 100) {
                    setTimeout(updateCounter, 20)
                  }
                  else if (target <= 300) {
                    setTimeout(updateCounter, 10)
                  } else {
                    setTimeout(updateCounter, 6)
                  }

                } else {
                  counter.innerText = target
                }
              }
              updateCounter()
            })
          }, 0);

    }
  },
  components: {}
};
</script>


<style lang="scss" scoped>
#introSection {

  // padding: 5rem 0;

  .text-h4 {
    font-size: 40px !important;
    line-height: 48px !important;
  }

  .subtitle-2 {
    font-size: 15px !important;
  }

  .subtitle-1 {
    text-transform: capitalize;
    opacity: 0.65;
    font-weight: 600;
    text-align: end;
  }

  .statisticsCard {
    margin-top: 3rem;
    box-shadow: none;

    .imgContainer {
      background: #edf3f6;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;

      // img {
      //   &:nth-of-type(1) {
      //     display: block;
      //   }

      //   &:nth-of-type(2) {
      //     display: none;
      //   }
      // }
    }

    &:hover {
      .imgContainer {
        background: #0675bc !important;

        img {
          -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
        }

        //   &:nth-of-type(2) {
        //     display: block;
        //   }
        // }
      }
    }
  }
}
</style>