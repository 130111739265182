<template>
  <v-navigation-drawer color="light"  v-model="$store.state.sidebarIsOpen" :right="!$vuetify.rtl"
    :mobile-breakpoint="910" width="305" app>
    <v-list nav dense>
      <v-list-item-group active-class="gray2--text blue13">
        <v-icon class="pa-2 d-block primary--text mi-start-auto" @click="$store.state.sidebarIsOpen = false">mdi mdi-close</v-icon>
        <div class="d-flex justify-center mb-2">
          <v-btn class="white--text mx-1 primary rounded-pill" large elevation="0" :to="`/consultations`">
            {{ $t('Consult now') }}
          </v-btn>
          <v-btn class="black--text  mx-1 gray11  rounded-pill font-weight-bold" @click="$router.push('/profile')"
            active-class="blue12" v-if="$store.state.isAuth" id="login_btn" large elevation="0">
            <v-img max-width="24" src="@/assets/img/png/avatar_icon.png" class="me-2"></v-img>
            {{ $t('my profile') }}
          </v-btn>
          <v-btn class="black--text  mx-1 gray11  rounded-pill font-weight-bold" large v-else :to="`/authentication`" id="login_btn"
            elevation="0">
            {{ $t('log in') }}
          </v-btn>
        </div>

        <v-list-item class="justify-end align-end py-2" v-for="(nav, i) in $store.state.sections?.header?.menu" :key="i">
          <v-list-item-title v-if="nav.active">
            <v-btn plain @click="scrollTo(nav)" class="font-weight-bold" block>
              {{ nav[`name_${$i18n.locale}`] }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>


        <v-list-item class="justify-center text-center align-end py-2">
          <v-list-item-title>
            <v-btn class="black--text shadow" color="white" @click="changeLanguage" :title="$t('reverseLanguage')" x-large  block>
              <span class="mx-2">{{ $i18n.locale === 'ar' ? $t('english') : $t('arabic') }}</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>

      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>


export default {
  name: "SideBar",
  computed: {

  },
  data: () => ({}),

  components: {

  },
  mounted() {

  },
  methods: {
    scrollTo(section) {
      let sectionName = section.name_en.replaceAll(' ', '')
      console.log('section', section);
      if (section.type == 2) {
        this.$router.push('/consultations');
      }
      else if (section.type == 3) {
        this.$router.push(`/blogs`);
      }
      else {
        if (this.$route.path === '/') {
          if (this.$i18n.locale == 'ar')
            this.$vuetify.goTo(`.${sectionName}_scroll`)
          else
            this.$vuetify.goTo(`/${this.$i18n.locale}.${sectionName}_scroll`)
        } else {
          this.$router.push(`/${this.$i18n.locale}`);
          setTimeout(() => {
            this.$vuetify.goTo(`.${sectionName}_scroll`)
          }, 1);
        }
      }
    },
    changeLanguage() {
      // location.reload();
      this.$store.dispatch("Settings/changeLanguage");
    },


  },
};
</script>

