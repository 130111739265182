import Vue from 'vue';



// Inputs
import GenericInput from "@/components/ui/GenericInput.vue";
import FloatInput from "@/components/inputs/FloatInput.vue";
// import DropZone from "@/components/inputs/DropZone.vue";
import AutoCompleteInput from "@/components/inputs/AutoCompleteInput.vue";
import ComboBoxInput from "@/components/inputs/ComboBoxInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";
import DateInput from "@/components/inputs/DateInput.vue";
import TimeInput from "@/components/inputs/TimeInput.vue";
import EmailInput from "@/components/inputs/EmailInput.vue";
import TextAreaInput from "@/components/inputs/TextAreaInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import MobileInput from "@/components/inputs/MobileInput.vue";
import FileInput from "@/components/inputs/FileInput.vue";
import CheckBoxInput from "@/components/inputs/CheckBoxInput.vue";
import RadioInput from "@/components/inputs/RadioInput.vue";
import SwitchInput from "@/components/inputs/SwitchInput.vue";
import TextEditor from "@/components/inputs/TextEditor.vue";
import PercentageInput from "@/components/inputs/PercentageInput.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";




Vue.component("GenericInput", GenericInput);
Vue.component("FloatInput", FloatInput);
// Vue.component("DropZone", DropZone);
Vue.component("AutoCompleteInput", AutoCompleteInput);
Vue.component("ComboBoxInput", ComboBoxInput);
Vue.component("TextInput", TextInput);
Vue.component("NumberInput", NumberInput);
Vue.component("DateInput", DateInput);
Vue.component("TimeInput", TimeInput);
Vue.component("EmailInput", EmailInput);
Vue.component("TextAreaInput", TextAreaInput);
Vue.component("PasswordInput", PasswordInput);
Vue.component("MobileInput", MobileInput);
Vue.component("FileInput", FileInput);
Vue.component("CheckBoxInput", CheckBoxInput);
Vue.component("RadioInput", RadioInput);
Vue.component("SwitchInput", SwitchInput);
Vue.component("TextEditor", TextEditor);
Vue.component("PercentageInput", PercentageInput);
Vue.component("AnimatedLoading", AnimatedLoading);





export default ({
});