<template>
  <v-autocomplete auto-select-first :solo="solo" outlined :reverse="reverse" :dense="dense" :name="name"
    @keydown.enter="keydownEnter()" @keydown="keydown" :class="classes" v-model="input" :items="lookups ? lookups : []"
    :item-text="selected_label" :item-value="selected_prop" :append-icon="icon ? icon : 'mdi-menu-down'"
    :item-disabled="checkIsItemDisabled" :color="color" :background-color="background"
    :rules="required == true ? $global.requiredRule : $global.notRequiredRule" :multiple="multi ? true : false"
    :no-data-text="$i18n.t('no selects')" :placeholder="$i18n.t(placeholder)" :loading="isLoading" :disabled="disabled" :chips="multi" :small-chips="multi"
    :hide-details="hideDetails == true ? 'auto' : false" :clearable="clearable || multi" :deletable-chips="multi"
    :error-messages="$i18n.t(errorMessage)" :persistent-hint="hint ? true : false" :hint="$i18n.t(hint)">
    <template v-slot:prepend-item v-if="Array.isArray(value)">
      <v-list-item v-if="multi" ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="value.length && value.length == lookups.length ? 'error' : color">
            {{ checkIcon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('select all') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="multi" class="mt-2"></v-divider>
    </template>
    <template v-slot:label>
      <label v-if="inputDesign == 'default'">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true">*</span>
      </label>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
    <template v-if="multi || has_slot" v-slot:selection="{ item, index, select, selected, disabled }">
      <slot v-if="has_slot" name="selection" :item="item" :index="index" :select="select" :selected="selected"
        :disabled="disabled" />
      <div v-else>
        <v-chip v-if="index <= 2">
          <span>{{ item[selected_label] || item }}</span>
        </v-chip>
        <span v-if="index === 3" class="grey--text text-caption">
          ( +{{ value.length - 3 }} {{ $t('other selections') }} )
        </span>
      </div>
    </template>
    <template v-if="has_slot" v-slot:item="{ item }">
      <slot name="item" :item="item" />
    </template>
  </v-autocomplete>
</template>


<script>
export default {
  name: "AutoCompleteInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    lookups: { default: null },
    required: { default: false },
    dense: { default: true },
    lookups_value: { default: null },
    multi: { default: false },
    selected_label: { default: null },
    selected_prop: { default: null },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "primary" },
    background: { default: null },
    icon: { default: "" },
    hideDetails: { default: false },
    inputDesign: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    disableItem: { default: null },
    placeholder: { default: null },
    errorMessage: { default: null },
    hint: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false },
    has_slot: { default: false },
    tabKey: { default: Function },
  },
  data: () => ({

  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    selectAll() {
      return this.lookups.length === this.value.length
    },
    selectSome() {
      return this.lookups.length > 0 && !this.selectAll
    },
    checkIcon() {
      if (this.selectAll) return 'mdi-close-box'
      if (this.selectSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.$emit("input", []);
        } else {
          let ids = []
          this.lookups.slice().forEach(item => {
            ids.push(item[this.selected_prop])
          });
          this.$emit("input", ids);
        }
      })
    },
    checkIsItemDisabled(item) {
      if (this.disableItem) {
        if (item[this.disableItem.key] == this.disableItem.value && typeof this.disableItem !== "string") {
          return true;
        }
        if (typeof this.disableItem === "string") {
          return item.disable
        }
        return false;
      }
      else {
        return false;
      }
    },
    keydown(e) {
      console.log(e);
      if (e.code == "Tab") {
        this.tabKey(true)
      }
    },
  },
};
</script>



<style scoped lang="scss"></style>
