import { render, staticRenderFns } from "./ComboBoxInput.vue?vue&type=template&id=b2cb4242&scoped=true&"
import script from "./ComboBoxInput.vue?vue&type=script&lang=js&"
export * from "./ComboBoxInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2cb4242",
  null
  
)

export default component.exports