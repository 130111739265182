<template>
  <section id="appBar" :class="isFixed ? 'fixedBar primary' : 'absoluteBar transparent'">
    <v-container fluid class="py-0">
      <v-row align="center" justify="space-around">
        <v-col cols="auto">
          <img :src="$store.state.endpointURL + $store.state.sections?.header?.logo" height="60" alt="Logo">
        </v-col>

        <v-col cols="auto" class="d-none d-lg-block">
          <v-row>
            <v-col cols="auto" class="pa-0" v-for="(nav, i) in $store.state.sections.header.menu" :key="i + 3">
              <!-- <v-btn-toggle v-model="toggle_exclusive" mandatory> -->
              <v-btn @click="scrollTo(nav, nav.type)" id="nav_btn" color="primary" link text plain
                class="font-weight-bold" x-large>
                <div>
                  <div>{{ nav[`name_${$i18n.locale}`] }}</div>
                  <div id="nav_effect"></div>
                </div>
              </v-btn>
              <!-- {{ toggle_exclusive}}
              </v-btn-toggle> -->
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="d-none d-lg-block">
          <v-btn plain class="black--text font-weight-bold shadow" @click="changeLanguage" :title="$t('reverseLanguage')">
            {{ $i18n.locale == 'en' ? 'AR' : 'EN' }}
          </v-btn>
          <v-btn class="white--text blue2 mx-3" min-width="130" elevation="0" :to="`/consultations`">
            {{ $t('Consult now') }}
          </v-btn>
          <v-btn class="black--text gray11 font-weight-bold" @click="$router.push('/profile')" active-class="blue12" v-if="$store.state.isAuth" id="login_btn" elevation="0">
            <v-img max-width="24" src="@/assets/img/png/avatar_icon.png" class="me-2"></v-img>
            {{ $t('my profile') }}
          </v-btn>
          <v-btn class="black--text gray11 font-weight-bold" :to="`/authentication`" v-else id="login_btn" elevation="0">
            {{ $t('log in') }}
          </v-btn>
        </v-col>
        <v-col cols="auto" class="d-lg-none">
          <v-btn plain color="white" @click="toggleSidebar" x-large icon><v-icon
              color="darkblue">mdi-menu</v-icon></v-btn>
        </v-col>
        <!-- <v-col cols="12" v-if="!isFixed">
          <hr />
        </v-col> -->
      </v-row>
    </v-container>
  </section>
</template>
<script>


export default {
  name: "AppBar",
  props: {
    isFixed: { default: false },
  },
  computed: {
    convertHasLink() {
      return (nav) => `#${(nav.name_en).replaceAll(' ', '')}`
    }
  },
  data: () => ({ toggle_exclusive: null }),

  components: {

  },
  mounted() {
    console.log("sas", this.$route.hash.slice(1));
  },
  methods: {
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      // location.reload();
      this.$store.dispatch("Settings/changeLanguage");
    },
    scrollTo(section) {
      let sectionName = section.name_en.replaceAll(' ', '')
      console.log('section', section);
      if (section.type == 2) {
        this.$router.push('/consultations');
      }
      else if (section.type == 3) {
        this.$router.push(`/blogs`);
      }
      else {
        if (this.$route.path === '/') {
          if (this.$i18n.locale == 'ar')
            this.$vuetify.goTo(`.${sectionName}_scroll`)
          else
            this.$vuetify.goTo(`/${this.$i18n.locale}.${sectionName}_scroll`)
        } else {
          this.$router.push(`/${this.$i18n.locale}`);
          setTimeout(() => {
            this.$vuetify.goTo(`.${sectionName}_scroll`)
          }, 1);
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#appBar {
  background: white !important;
  z-index: 500;
  width: 100vw;

  hr {
    opacity: 0.1;
  }

  &.fixedBar {
    position: fixed;
    top: 0;
  }

  &.absoluteBar {
    position: absolute;
    padding: 0;
    height: 68px;
  }

  #login_btn {
    border-radius: 20px;
  }

  #nav_effect {
    width: 0%;
    height: 1px;
    background: darkblue;
    transition: width 0.5s;
    margin-top: 1px;
  }

  #nav_btn:hover #nav_effect {
    width: 100%;
  }

  // .v-btn--active {
  //   background: red !important;
  // }

  // img {
  //         -webkit-filter: brightness(0) invert(1);
  //         filter: brightness(0) invert(1);
  //       }
}
</style>