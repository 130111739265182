<template>
  <v-container class="mt-5 d-flex align-center">
    <v-form ref="form" v-model="valid" lazy-validation class="mt-16 w-100">
      <v-row>

        <!-- Profile Card -->
        <v-col cols="12" lg="4" class="mb-5">
          <v-expand-transition>
            <v-card class="profile-card d-flex justify-center py-9">
              <div>
                <v-img max-width="128" class="mx-auto" src="@/assets/img/png/avatar_icon.png"></v-img>

                <!-- show data -->
                <div v-if="!edit_profile" class="ma-0 pa-0">
                  <span class="d-flex mt-7">
                    <v-img max-width="17" src="@/assets/img/png/mail_icon.png"></v-img>
                    <span class="company-name px-3">{{ $store.state.userData.company_name }}</span>
                  </span>

                  <h4 class="mt-5">{{ $store.state.userData.full_name }}</h4>
                  <a href="#" class="d-block mt-5">{{ $store.state.userData.email }}</a>
                  <a href="#" class="d-block mt-5">{{ $store.state.userData.mobile }}</a>

                  <v-btn @click="edit_profile = true" class="white--text blue6 px-5 mt-9 " block elevation="0">
                    {{ $t('Edit profile') }}
                  </v-btn>
                  <v-btn class="error--text transparent px-5 mt-5 " block elevation="0"  @click="$api.LOGOUT(true)">
                    {{ $t('Logout') }}
                  </v-btn>
                </div>

                <!-- Edit Profile -->
                <div v-if="edit_profile" class="edit-profile mt-8">

                  <GenericInput type="email" :value="form.email" @input="form.email = $event" label="email"
                    :required="true" :isLoading="false" :cols="[12, 12, 12]" hint="Field is required"
                    placeholder="ex : support@valuesolutionfc.com" />

                  <GenericInput type="text" :value="form.full_name" @input="form.full_name = $event" label="name"
                    :required="true" :isLoading="false" :cols="[12, 12, 12]" hint="Field is required"
                    placeholder="ex : mohamed ahmed" />

                  <GenericInput type="mobile" :value="form.mobile" @input="form.mobile = $event" label="mobile"
                    :required="true" :isLoading="false" :cols="[12, 12, 12]" hint="Field is required"
                    placeholder="ex : 056789910" />
                  <!-- 
                  <GenericInput type="email" :value="form.confirm_email" @input="form.confirm_email = $event"
                    label="confirm email" :required="true" :isLoading="false" :cols="[12, 12, 12]"
                    :validation_type="confirmValidation" hint="Field is required"
                    placeholder="ex : support@valuesolutionfc.com" /> -->

                  <GenericInput type="text" :value="form.company_name" @input="form.company_name = $event"
                    label="company name" :required="true" :isLoading="false" :cols="[12, 12, 12]"
                    placeholder="ex : aramco" />

                  <v-btn @click="save" class="white--text blue6 px-5 mt-3 " :disabled="!(valid)" :loading="isSaveLoading"
                    block elevation="0">
                    {{ $t('Save') }}
                  </v-btn>

                </div>
              </div>
            </v-card>
          </v-expand-transition>
        </v-col>

        <!-- My reservations -->
        <v-col cols="12" lg="8" v-if="!isLoading && reservations.length">
          <h4 class="my-6">{{ $t('My reservations') }}</h4>
          <v-row>
            <v-col cols="12" class="reservations-row" v-for="reservation in reservations" :key="reservation.id">
              <v-card class="my-reservations-card d-flex pa-4">
                <div class="w-100">
                  <v-row justify="space-between">
                    <v-col cols="auto">
                      <h4 class="mt-3">{{ reservation.date | dateAr }}, {{ $t('clock') }} {{ reservation.time | time }}</h4>
                    </v-col>
                    <v-col cols="auto">
                      <h4 class="mt-3">{{ reservation.amount | float }} {{ $t('ryal') }}</h4>
                    </v-col>
                  </v-row>
                  <span class="d-flex align-center mt-3">
                    <v-img max-width="30" :src="require('@/assets/img/svg/consulting.svg')"
                      :alt="reservation.consultation_name" />
                    <span class="company-name px-3">{{ reservation.consultation_name }}</span>
                  </span>
                  <span class="d-flex align-center mt-3">
                    <v-img max-width="30"
                      :src="reservation.type == 1 ? require('@/assets/img/svg/bookInOffice.svg') : require('@/assets/img/svg/bookInOnline.svg')" />
                    <span class="company-name px-3">{{ reservation.type == 1 ? $t('in office') : $t('online')
                    }}</span>
                  </span>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="8" class="pt-16" v-if="!isLoading && !reservations.length">
          <v-alert type="blue2" text outlined>
            {{ $t('no booking yet') }}
          </v-alert>
        </v-col>
        <v-col cols="12" lg="8" class="pt-16" v-else>
          <AnimatedLoading :isLoading="isLoading" :height="85" :hideText="true" />
        </v-col>
      </v-row>
    </v-form>


  </v-container>
</template>
  
  
<script>

export default {

  name: "ProfilePage",

  data: () => ({
    valid: false,
    isLoading: false,
    isSaveLoading: false,
    edit_profile: false,
    reservations: [],
    form: {
      email: null,
      name: null,
      mobile: null,
      confirm_email: null,
      company_name: null,
    }
  }),
  watch: {
    '$store.state.userData.id'() {
      this.form = { ...this.$store.state.userData }
      this.form.user_full_name = this.form.full_name;
    },
    '$store.state.isAuth'() {
      if (this.$store.state.isAuth) {
        this.form = { ...this.$store.state.userData }
        this.form.user_full_name = this.form.full_name;
        this.getData();
      }
    },
  },
  mounted() {
    if (this.$store.state.isAuth) {
      this.form = { ...this.$store.state.userData }
      this.form.user_full_name = this.form.full_name;
      this.getData();
    }else{
      this.$router.push('/')
    }
  },

  computed: {
    disabledSaveBtn() {
      return (
        Object.keys(this.form).some(key => !this.form[key])
      );
    },

    checkScreenWidth() {
      return this.$vuetify.breakpoint.width >= 1264;
    },
    confirmValidation() {
      return [(v) => !!(v || "").match(/@/) || this.$i18n.t("Field is required"),
      (v) =>
        (v && v == this.registerForm.email) ||
        this.$i18n.t("email not matches")]
    }
  },

  methods: {
    getData() {
      this.isLoading = true
      this.$api.GET_METHOD(`my_booking`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.reservations = response.data.items || []
        }
      })
    },

    save() {
      if (this.$refs.form.validate()) {
        this.isSaveLoading = true;
        this.form._method = 'PUT'
        delete this.form.image
        delete this.form.user
        this.$api.POST_METHOD(`update_my_profile`, this.form).then((response) => {
          this.isSaveLoading = false;
          if (response.check) {
            this.getData(false);
            Object.keys(this.form).forEach(key => this.form[key] = null);
            this.$api.SET_AUTH(response.data)
            this.edit_profile = false;
          } else {
            this.$api.ShowModal('error', response.msg);
          }
        })
      }
    },
  },


  components: {
  },
}
</script>

<style lang="scss" scoped>
// Profile Card
.profile-card {
  border-radius: var(--3, 1rem) !important;
  background: var(--White, #FFF) !important;
  box-shadow: 0px 9px 176px 0px rgba(0, 0, 0, 0.07) !important;


}

.profile-card .company-name {
  color: var(--gray-3, #34303E);
  font-size: 0.85rem;
  font-weight: 400;
}

.profile-card a {
  color: blue;
  text-decoration: underline;
  font-weight: bold;
}



// My Reservations
.my-reservations-card {
  border-radius: var(--3, 1rem) !important;
  background: var(--White, #FFF) !important;
  box-shadow: 0px 9px 176px 0px rgba(0, 0, 0, 0.07) !important;

}


.reservations-row {
  max-height: 100%;
}

.reservations-col::-webkit-scrollbar {
  width: 1.9px;
}

.reservations-col::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}
</style>